<template>
	<ValidationObserver
		class="h-100"
		@submit.prevent="sendUser()"
		ref="formEditUser"
		tag="form"
		v-if="user !=''"
	>
		<div class="col-md-3">
			<div class="text-center">
				<div class="image_div mx-auto position-relative">
					<img
						:src="user.photo != null && user.photo.includes('data')  ? user.photo : getImg(user.photo)"
						class="img_user"
						@error="errorImage"
					/>
					<input
						class="btnAlteraFoto position-absolute"
						type="file"
						@change="onChoseFile"
						id="file"
						accept="image/x-png, image/gif, image/jpeg"
						placeholder="Alterar"
					/>
				</div>
				<img v-if="this.sizeWindow <= 767" class="editImage" src="../../assets/image/iconesMobile/changeImageIcon.svg">
				<a v-if="this.sizeWindow > 767" href="#" class="mb-0">Alterar foto</a>

				<div class="cpfCont">
					<h4 v-if="this.sizeWindow <= 767"  style="color: #FFFF" id="userCpf">CPF: </h4><h4 :style="this.sizeWindow <= 767 ? 'color:#98C4E6' : 'color: #e0a638'" class="mb-0" id="userCpf"> {{cpfMask(user.cpf)}}</h4>
				</div>
			</div>
		</div>
		<div class="col-md-8 campos">
			<div class="row">
				<ValidationProvider
					class="col-md-7 mb-2"
					tag="div"
					rules="required"
					name="Nome Completo"
					v-slot="{ errors, ariaMsg, classes }"
				>
					<label class="mb-1">Nome completo:</label>
					<input type="text" class="input_default form-control" v-model="user.name" :class="classes" />
				</ValidationProvider>
				<ValidationProvider
					class="col-md-5 mb-2"
					tag="div"
					rules="required|oneOf:1,2"
					name="Sexo"
					v-slot="{ errors, ariaMsg, classes }"
				>
					<label class="mb-1">Sexo:</label>
					<div class="checkbox_div row align-items-center">
						<div class="col-md-6">
							<div class="d-flex align-items-center checkboxes justify-content-end">
								<input type="radio" id="checkbox_1" :value="1" v-model="user.genre" :class="classes" />
								<label for="checkbox_1" class="mb-0" :class="classes">Masculino</label>
							</div>
						</div>
						<div class="col-md-6">
							<div class="d-flex align-items-center checkboxes justify-content-end">
								<input type="radio" id="checkbox_2" :value="2" v-model="user.genre" :class="classes" />
								<label for="checkbox_2" class="mb-0" :class="classes">Feminino</label>
							</div>
						</div>
					</div>
				</ValidationProvider>
				<ValidationProvider
					class="col-md-4 mb-2"
					rules="required"
					name="Nome social"
					v-slot="{ errors, ariaMsg, classes }"
				>
					<label class="mb-1">Nome social:</label>
					<input
						type="text"
						class="input_default form-control"
						v-model="user.displayName"
						:class="classes"
					/>
				</ValidationProvider>
				<ValidationProvider
					class="col-md-4 mb-2"
					rules="required|email"
					name="Email"
					v-slot="{ errors, ariaMsg, classes }"
				>
					<label class="mb-1">Email:</label>
					<input type="email" v-model="user.email" class="input_default form-control" :class="classes" />
				</ValidationProvider>
				<ValidationProvider
					class="col-md-4 mb-2"
					rules="required|min:10"
					name="Celular"
					v-slot="{ errors, ariaMsg, classes ,validate}"
				>
					<label class="mb-1">Celular:</label>
					<the-mask
						type="text"
						v-model="user.cellPhone"
						:mask="['(##) ####-####', '(##) #####-####']"
						class="input_default form-control"
						:class="classes"
						@blur.native="validate($event)"
						:masked="true"
					/>
				</ValidationProvider>
				<div class="col-md-6">
					<label class="mb-1">Perfil:</label>
					<div class="checkbox_div row align-items-center">
						<div class="col-md-4">
							<div class="d-flex align-items-center checkboxes">
								<input
									type="radio"
									id="checkbox_3"
									value="1"
									v-model="user.profile"
									v-if="user.profile == 1 "
								/>
								<label for="checkbox_3" class="mb-0">Master</label>
							</div>
						</div>
						<div class="col-md-4">
							<div class="d-flex align-items-center checkboxes justify-content-center">
								<input
									type="radio"
									id="checkbox_4"
									value="2"
									v-model="user.profile"
									v-if="user.profile == 2 "
								/>
								<label for="checkbox_4" class="mb-0">Franqueado</label>
							</div>
						</div>
						<div class="col-md-4">
							<div class="d-flex align-items-center checkboxes">
								<p class="mb-0 mr-1"></p>
								<input
									type="radio"
									id="checkbox_5"
									value="3"
									v-model="user.profile"
									v-if="user.profile == 3"
								/>
								<label for="checkbox_5" class="mb-0">Vendedor</label>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-3 ml-auto mt-auto">
					<button class="btn_default" type="submit" :disabled="disabled ">
						<span
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
							v-show="disabled"
						></span>
						{{ !disabled ? `Salvar` : "" }}
					</button>
				</div>
			</div>
		</div>
	</ValidationObserver>
</template>

<script>
	import { TheMask } from "vue-the-mask";
	import HTTP from "@/api_system";
	export default {
		components: { TheMask },
		data() {
			return {
				disabled: false,
				user: [],
				sizeWindow: 0,
			};
		},
		mounted() {
			this.sizeWindow = $(window).width();
			this.user = Object.assign({}, this.userAsing);
			// this.$store.dispatch("cpfForLogin", this.user.cpf.toString());
		},
		watch: {},
		methods: {
			sendUser() {
				this.$refs.formEditUser.validate().then((success) => {
					if (success) {
						this.disabled = true;
						var objPost = {
							id: this.user.id,
							displayName: this.user.displayName,
							name: this.user.name,
							email: this.user.email,
							photo: this.user.photo,
							cpf: this.user.cpf.toString(),
							profile: this.user.profile,
							cellPhone: this.user.cellPhone,
							genre: parseInt(this.user.genre),
						};
						this.$store.dispatch("editCurrentUser", objPost).then((resolve) => {
							this.disabled = false;
							if (resolve.success) {
								this.$notify({
									group: "erros",
									type: "sucess",
									text: `<i class="icon ion-close-circled"></i>${resolve.msg}`,
								});
								this.$store.dispatch("cpfForLogin", this.user.cpf.toString());
							} else {
								this.$notify({
									group: "erros",
									type: "error",
									text: `<i class="icon ion-close-circled"></i>${resolve.msg}`,
								});
							}
						});
					}
				});
			},
			onChoseFile: function (event) {
				// Reference to the DOM input element
				var input = event.target;

				// Ensure that you have a file before attempting to read it
				if (input.files && input.files[0]) {
					if (Math.round(input.files[0].size / 1024) >= 10000) {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i> Ooops, Arquivo maior que 1MB! Por favor ultilize um compressor ou selecione outro arquivo! \r Tamanho: ${Math.round(
								input.files[0].size / 1024
							)}KB`,
						});

						$("#file").val("");
						return false;
					}
					// create a new FileReader to read this image and convert to base64 format
					var reader = new FileReader();
					// Define a callback function to run, when FileReader finishes its job
					reader.onload = (e) => {
						// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
						// Read image as base64 and set to imageData//
						this.user.photo = e.target.result;
					};
					// Start the reader job - read file as a data url (base64 format)
					reader.readAsDataURL(input.files[0]);
				}
			},
		},
		computed: {
			userAsing() {
				return this.$store.state.login.userLogin;
			},
		},
	};
</script>

<style scoped>
.cpfCont{
	display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cpfCont h4{
	margin-bottom: 0;
	padding-top: 0;
}
.input_default {
	border: 1px solid #e3e3e3 !important;
	color: #ffffff;
	font-size: 14px;
	height: 40px;
	background-color: #868686 !important;
}
.btn_default {
	background-color: #e0a638;
	height: 40px;
	border: none;
}
label,
p {
	color: #ffffff !important;
}
.checkboxes input[type="radio"] {
	opacity: 0;
	width: 0;
}

.checkboxes label {
	position: relative;
	display: inline-block;
	font-size: 12px;
	padding-right: 15px;
	font-weight: 600;
}
.btn_default:hover {
	background-color: #f38235;
}
.checkboxes label::before {
	position: absolute;
	content: "";

	/*Needed for the line-height to take effect*/
	display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkboxes label::before {
	height: 12px;
	width: 12px;
	border: 1px solid;
	right: 0px;
	border-radius: 50%;
	background-color: #6198c2;
	top: 0px;
	bottom: 0;
	margin-bottom: auto;
	margin-top: auto;
}

.checkboxes input[type="radio"]:checked + label::before {
	background-color: #ffffff;
}
a {
	color: #ffffff;
}
.image_div {
	border-radius: 50%;
	border: 3px solid #2474b2;
	height: 100px;
	width: 100px;
	overflow: hidden;
}
.image_div .img_user {
	object-fit: cover;
	min-height: 100%;
	width: 100%;
	object-position: top;
}
h4 {
	font-size: 18px;
	color: #e0a638;
}
.input_default.failed {
	border: 2px solid #dc3545 !important;
}
.input_default.is-valid {
	border: 2px solid #28a745 !important;
}
.checkboxes label.failed::before {
	border: 2px solid #dc3545 !important;
}

.image_div::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	background-color: black;
	height: 100%;
	opacity: 0;
	transition: all 0.3s ease-in-out;
}
.image_div:hover::after {
	contain: "";
	opacity: 0.8;
}

.btnAlteraFoto::-webkit-file-upload-button {
	visibility: hidden;
}
.btnAlteraFoto::after {
	content: "Alterar foto";
	color: #fff;
	outline: none;
	position: absolute;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 11px;
	opacity: 0;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease-in-out;
}
.image_div:hover .btnAlteraFoto::after {
	opacity: 1;
}
.btnAlteraFoto {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
}
.editImage{
	cursor: pointer;
}
.input_default:read-only {
	color: #e3e3e3 !important;
}
@media screen and (max-width:767px){
	.image_div{
		border: 4px solid #F38235;
	}
	.editImage{
		position: absolute;
		top: 45%;
		right: 36%;
	}
	#userCpf{
		padding-top: 3%;
	}
	label{
		font-style: normal;
		font-weight: 400!important;
		font-size: 14px!important;
		line-height: 21px!important;
		color: #98C4E6!important;
	}
	.checkboxes{
		display: flex!important;
    	justify-content: flex-start!important;
	}
	.campos{
		padding-top: 7%;
	}
	.campos input{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 15px 16px 12px 12px;
		gap: 10px;
		background: #5B9CCF!important;
		border: 1px solid #3F88C1!important;
		border-radius: 8px!important;
	}
	.btn_default{
		margin-top: 3%!important;
	}
	.checkboxes p{
		margin-right: 0!important;
	}
}
@media screen and (max-width:450px){
	.editImage{
		position: absolute;
		top: 45%;
		right: 33%;
	}
}
@media screen and (max-width:370px){
	.editImage{
		position: absolute;
		top: 45%;
		right: 30%;
	}
}
</style>
