<template>
  <div class="col-md-12 unidades">
    <div class="row" v-if="$store.getters.getUser.profile !=2">
      <div class="col-md-3">
        <div class="d-flex align-items-center select">
          <div class="cargo d-flex align-items-center justify-content-center" >
            <span>{{$store.getters.tenantSigla}}</span>
          </div>
          <p class="mb-0 ml-3">{{tenant.tenancyName}}</p>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div
        class="col-md-3"
        v-for="(item,index) in unidades"
        :key="index"
        @click.prevent="enterTenant(item)"
      >
        <div
          class="d-flex align-items-center"
          :class="item.reference == $store.getters.tenantSigla ?'select':'unselect'"
          style="cursor:pointer;"
        >
          <div class="cargo d-flex align-items-center justify-content-center">
            <span>{{item.reference}}</span>
          </div>
          <p class="mb-0 ml-1" id="unityName">{{item.tenanancyName}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  data() {
    return {
      unidades: []
    };
  },
  mounted() {
    this.getUnidades();
  },
  methods: {
    getUnidades() {
      this.load = true;
      HTTP.get("services/app/User/GetMyTenants", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.unidades = response.data.result;

          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });
    },
    enterTenant(item) {
      this.disabled = true;
      HTTP.post(
        `TokenAuth/AuthenticateFromMaster?tenantId=${item.tenantId}&userId=${item.userId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$nextTick(() => {
            this.disabled = false;
            this.$store.commit("set_token", response.data.result.accessToken);
            this.$store.commit("set_user", response.data.result.informations);
            this.$store.commit("set_tenant", response.data.result.tenant);
            this.$router.push("/");
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>Unidade selecionada!`
            });
          });
        })
        .catch(error => {
          this.disabled = false;
        });
    }
  },
  computed: {
    tenant() {
      return this.$store.getters.tenantRequire;
    }
  }
};
</script>

<style scoped>
.cargo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2474b2;
}
.select .cargo {
  border: 2px solid#fff;
}
.select p {
  color: #fff;
}
.select span {
  color: #fff;
}
.unselect .cargo {
  border: 2px solid#175d93;
}
.unselect p {
  color: #175d93;
}
.unselect span {
  color: #175d93;
}
.cargo span {
  font-weight: 500;
  font-size: 10px;
}
p {
  font-weight: 500;
}
@media screen and (max-width:767px){
  #unityName{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
  }
  .cargo{
    margin-right: 15px;
    border: 2px solid #2474B2!important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #98C4E6;
  }
  .select span {
    color: #2474B2;
}
.cargo span {
    font-weight: 500;
    font-size: 13px;
}
}
</style>